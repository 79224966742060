<template>
  <Container size="small" class="print:hidden">
    <ProTip :title="title" :button-label="buttonText" :button-href="buttonHref">
      <RichText :content="text" />
    </ProTip>
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      title() {
        return this.getValue("title")
      },
      text() {
        return this.getRichtext("text")
      },
      buttonText() {
        return this.getValue("button")
      },
      buttonHref() {
        return this.getIngredient("button")?.linkUrl || " "
      },
    },
  }
</script>
