<template>
  <div class="pro-tip">
    <span class="label">{{ title }}</span>
    <p class="pro-tip-text">
      <slot />
    </p>
    <Button
      v-if="buttonLabel"
      :to="buttonHref"
      color="gray"
      layout="ghost"
      size="small"
      class="pro-tip-button"
    >
      {{ buttonLabel }}
    </Button>
  </div>
</template>

<script>
  import Button from "~/components/Button"

  export default {
    components: { Button },
    props: {
      title: {
        type: String,
        default: "Pro Tip!",
      },
      buttonLabel: {
        type: String,
        default: "",
      },
      buttonHref: {
        type: String,
        default: "",
      },
    },
  }
</script>

<style lang="scss" scoped>
  .pro-tip {
    border-radius: $border-radius;
    background: $silver-300;
    border: 1px $silver-500 solid;
    padding: $base-spacing * 6 $base-spacing * 8;
  }
  .label {
    @apply h5;
    display: block;
    color: $onyx;
  }
  .pro-tip-text {
    color: $gray-text;
    @apply text-sm leading-normal;
    margin: 0;
  }
  .pro-tip-button {
    margin-top: $space-s;
  }
</style>
